<!--
File: HomoSectionsList.vue
Description: show list of road sections entered in the DB.
-->
<template>
  <div class="md-layout">
    <md-card v-show="!showHomoSections">
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-15">
            <BaseDropdown id='yearFilter' :label="$t('budget.select_year')" v-model="selectedYear" :items="yearsInHSList()"
              :displayField="'year'" :valueField="'year'" />
          </div>
          <div class="md-layout-item btn-row md-small-size-100">
            <md-button class="md-success" @click="generateHS"> {{ $t('buttons.generate') }} </md-button>
          </div>
        </div>
      </md-card-header>

      <md-card-content>
        <md-progress-spinner v-if="showSpinner" class="md-progress-spinner" :md-diameter="70" md-mode="indeterminate" />
        <md-table class="paginated-table table-striped table-hover" :value="tableData" :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
          <md-table-empty-state :md-label="$t('label.no_data')"
            :md-description="$t('messages.select_another_criteria')" />
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <md-table-cell :md-label="$t('label.from_year')" md-sort-by="input_year_from">
              {{ item.input_year_from }}
            </md-table-cell>
            <md-table-cell :md-label="$t('label.to_year')" md-sort-by="input_year_to">
              {{ item.input_year_to }}
            </md-table-cell>
            <md-table-cell :md-label="$t('label.min_hs_length')" md-sort-by="input_min_hs_length" md-numeric>
              {{ item.input_min_hs_length | numFormat(0) }}
            </md-table-cell>
            <md-table-cell :md-label="$t('road_network.section_count')" md-sort-by="hs_count" md-numeric>
              {{ item.hs_count | numFormat(0) }}
            </md-table-cell>
            <md-table-cell :md-label="$t('stdCols.created_at')" md-sort-by="generated_on">
              {{ item.generated_on | dateFormat }}
            </md-table-cell>
            <md-table-cell :md-label="$t('budget.approved')" md-sort-by="approved_on">
              {{ item.approved_on | dateFormat }}
            </md-table-cell>
            <md-table-cell :md-label="$t('tables.actions')">
              <div class="cell-actions">
                <md-button class="md-just-icon md-success md-simple" :title="$t('buttons.details')"
                  @click="showDetails(item)">
                  <md-icon>preview</md-icon>
                </md-button>
                <md-button v-if="amIAdmin" class="md-just-icon md-danger md-simple" :title="$t('buttons.delete')"
                  @click.stop.prevent="handleDelete(item)">
                  <md-icon>delete</md-icon>
                </md-button>
                <template v-if="!item.approved_by_user_id">
                  <md-button class="md-success md-raised md-sm" @click="approveHS(item)">
                    {{ $t('buttons.approve') }}
                  </md-button>
                </template>
                <template v-else>
                  <md-button class="md-raised md-sm md-primary" @click="revokeHS(item)">
                    {{ $t('buttons.revoke') }}
                  </md-button>
                </template>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="footer-table md-table">
          <table>
            <tfoot>
              <tr>
                <th v-for="item in footerTable" :key="item" class="md-table-head">
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">{{ item }}</div>
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </md-card-content>
    </md-card>

    <div v-show="showHomoSections">
      <HomoSections :hsId="selectedHSId" @close="showHomoSections = false" />
    </div>

    <md-dialog :md-active.sync="showInputParams" :md-click-outside-to-close="false">
      <md-dialog-title>{{ $t('label.homo_sections') }}
        <md-button class='md-simple md-just-icon md-round modal-default-button' @click='showInputParams = false'>
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>
      <HomosectionSectionInputForm @input='showGeneratedHS' @close="showInputParams = false" />
    </md-dialog>
  </div>
</template>
<script>
  import Swal from 'sweetalert2'
  import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
  import HomoSections from './HomoSections.vue'
  import HomosectionSectionInputForm from './HomosectionSectionInputForm.vue'
  import { mapState, mapGetters } from 'vuex'
  import { customSortMixin } from '@/customSortMixin'
  import { numFormat } from "@/store/refdata/format_helpers"

  export default {
    mixins: [customSortMixin],
    name: 'homogeneous-sections-list',
    data() {
      return {
        selectedYear: null,
        selectedHSId: null,
        showHomoSections: false,
        showInputParams: false,
        showSpinner: false,
        propsToSearch: ['road', 'section_description'],
        currentSort: 'road',
        currentSortOrder: 'asc',
      }
    },
    components: {
      BaseDropdown,
      HomoSections,
      HomosectionSectionInputForm
    },
    mounted() {
      this.toggleSpinner(true)
      this.$store.dispatch('RESET_HS_LIST')
      this.reloadData()
    },

    methods: {
      toggleSpinner(state) {
        this.showSpinner = state
      },
      reloadData() {
        this.toggleSpinner(true)
        this.searchQuery = ''
        this.$store.dispatch('LOAD_HS_LIST', {}).then(() => {
          this.toggleSpinner(false)
        })
      },
      showDetails(item) {
        this.selectedHSId = item.homogeneous_section_list_id
        console.log('showDetails', this.selectedHSId)
        this.showHomoSections = true
      },
      generateHS() {
        this.showInputParams = true
      },
      showGeneratedHS(id) {
        console.log('showGeneratedHS', id)
        this.showInputParams = false
        this.showDetails({ homogeneous_section_list_id: id })
      },
      approveHS(item) {
        this.toggleSpinner(true)
        this.$store.dispatch('APPROVE_HS_LIST', item.homogeneous_section_list_id).then((res) => {
          this.reloadData()
          this.$emit('hsApproved', item.homogeneous_section_list_id)
          Swal.fire('Aproved', '', 'success')
          this.toggleSpinner(false)
        })
      },
      revokeHS(item) {
        this.toggleSpinner(true)
        this.$store.dispatch('CANCEL_HS_LIST', item.homogeneous_section_list_id).then((res) => {
          this.reloadData()
          this.$emit('hsApproved', item.homogeneous_section_list_id)
          Swal.fire('Revoked', '', 'success')
          this.toggleSpinner(false)
        })
      },
      handleDelete(item) {
        this.toggleSpinner(true)
        const alert = {
          title: this.$t('modal.are_you_sure'),
          html: this.$t('modal.you_want_delete', { name: item.year }) + '<br><br>' +
            this.$t('modal.the_operation_cannot_be_canceled'),
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: this.$t('modal.yes_delete'),
        }
        Swal.fire(alert).then((result) => {
          if (result.value) {
            this.$store.dispatch('DEL_HS_LIST', item.homogeneous_section_list_id).then(
              () => {
                this.reloadData()
                Swal.fire(this.$t('modal.deleted'), this.$t('modal.you_deleted', { name: item.year }), 'success')
                this.toggleSpinner(false)
              },
              (err) => {
                Swal.fire(this.$t('messages.error'), err, 'error')
              }
            )
          }
        })
      },
    },

    computed: {
      ...mapState({
        hsList: (state) => state.HomoSections.list,
        homoSections: (state) => state.HomoSections.hsList,
      }),
      ...mapGetters(['amIAdmin', 'yearsInHSList', 'hsListByYear']),
      total() {
        return this.tableData.length
      },
      tableData() {
        return this.hsListByYear(this.selectedYear)
      },
      footerTable() {
        return [
          this.$t('label.total'),
          ` ${numFormat(this.total, 0)} entries`
        ]
      }
    },

    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.cell-actions {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 80px;
  }

  .md-just-icon {
    margin: 3px 0;
    min-width: 10px;

  }
}

::v-deep .md-table-head-label {
  overflow: visible !important;
  white-space: normal !important;
  text-align: center !important;
  word-wrap: break-word !important;
}

.paginated-table table>tbody>tr>td {
  height: 36px;
  padding: 0px !important;
  padding-right: 24px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>