<!--
File: Preparatory.vue
Description: shows admin panel for preparatory step befor the Title list generations - creating
             homogeneous sections, generating worklists, etc..
-->
<template>
  <VueTabs>
    <v-tab id="tab0" :title="$t('label.homo_sections')">
      <HomoSectionsList />
    </v-tab>

    <v-tab id="tab1" :title="$t('planning.planned_works_table')">
      <PlannedWorks />
    </v-tab>

    <v-tab id="tab2" :title="$t('label.plans')">
      <Plans />
    </v-tab>

    <!--v-tab id="tab3" :title="$t('route.budget')">
      <Budgets />
    </v-tab-->
  </VueTabs>
</template>
<script>
  import { VueTabs, VTab } from 'vue-nav-tabs'
  import 'vue-nav-tabs/themes/vue-tabs.css'

  import HomoSectionsList from './HomoSectionsList.vue'
  import PlannedWorks from './PlannedWorks.vue'
  import Plans from './Plans.vue'
  //import Budgets from '../WorkPlan/Budgets.vue'

  export default {
    name: 'title-list-preparatory-steps-form',
    data() {
      return {
      }
    },

    components: {
      VueTabs,
      VTab,
      HomoSectionsList,
      PlannedWorks,
      Plans,
      //Budgets
    },

    mounted() {
    },

    methods: {
    },

    computed: {
    },
  }
</script>
<style lang="scss" scoped>
.vue-tabs .nav-tabs>li.active>a,
.vue-tabs .nav-tabs>li.active>a:hover,
.vue-tabs .nav-tabs>li.active>a:focus {
  background-color: #4caf50;
}
</style>