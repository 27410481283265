<!--
File: PlannedWorks.vue
Description: component of the Preparatory.vue almost same as WorkList component in TitleList.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout" style="align-items: center justify-content: flex-start width: auto">
        <div class="md-layout-item md-small-size-20 md-size-15">
          <BaseDropdown :label="$t('Saved worklists')" v-model="selectedWorklist" :items="savedWorksList"
            :displayField="'year'" :valueField="'work_list_id'" @input="loadTreatmentsList" />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-15">
          <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" data-vv-name="selectedRegion"
            :initial_value="selectedRegion" @input="loadTreatmentsList" />
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="checkAndGenerate"> {{ $t('Generate') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <div v-show="showSpinner" class="md-layout-item">
        <md-progress-spinner :md-diameter="100" :md-stroke="10" md-mode="indeterminate" />
      </div>
      <md-table class='"paginated-table table-striped table-hover' :value="tableData" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell :md-label="$t('road_network.region')" md-sort-by="region_description">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('stdCols.name')" md-sort-by="section_description">
            {{ item.section_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.hs_id')" md-sort-by="hs_id">
            {{ item.hs_id }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.length')" md-sort-by="start_distance_m" md-numeric>
            {{ item.start_m / 1000 | numFormat(3) }} - {{ item.end_m / 1000 | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('treatment.treatment_description')" md-sort-by="treatment_type_description">
            {{ item.treatment_type_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.cost')" md-sort-by="cost" md-numeric>
            {{ item.cost / showAmounts | numFormat }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="footer-table md-table">
        <table>
          <tfoot>
            <tr>
              <th v-for="item in footerTable" :key="item" class="md-table-head">
                <div class="md-table-head-container md-ripple md-disabled">
                  <div class="md-table-head-label">{{ item }}</div>
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
  import Swal from 'sweetalert2'
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
  import { mapState, mapGetters } from 'vuex'
  import { customSortMixin } from '@/customSortMixin'
  import { numFormat } from "@/store/refdata/format_helpers"

  export default {
    mixins: [customSortMixin],
    name: 'work-list-form',
    data() {
      return {
        showSpinner: true,
        selectedWorklist: null,
        selectedRegion: null,
        showAmounts: 1000000,
        amounts: [
          { id: 1, name: this.$t("soms") },
          { id: 1000, name: this.$t("Thousands") },
          { id: 1000000, name: this.$t("Millions") }
        ],
        showCurrency: 'kgs',
        currencies: [
          { id: 'kgs', name: 'KGS' },
          { id: 'usd', name: 'USD' }
        ],

        currentSort: 'section_description',
        currentSortOrder: 'asc',
      }
    },

    props: {
    },

    components: {
      RegionsDropdown,
      BaseDropdown
    },

    mounted() {
      this.loadWorksLists()
    },

    methods: {
      toggleSpinner(state) {
        this.showSpinner = state
      },
      loadWorksLists() {
        this.toggleSpinner(true)
        this.$store.dispatch('LOAD_WORKS_LISTS', {}).then(() => {
          this.selectedWorklist = this.savedWorksList.at(this.savedWorksList.length - 1).work_list_id
          //        this.loadTreatmentsList()
        })
        this.toggleSpinner(false)
      },
      loadTreatmentsList() {
        this.toggleSpinner(true)
        const params = {
          work_list_id: this.selectedWorklist,
          region_id: this.selectedRegion,
        }
        this.$store.dispatch('LOAD_ALL_WORKS', params).then(() => {
          this.toggleSpinner(false)
        })
      },
      async checkAndGenerate() {
        try {
          await this.$store.dispatch('LOAD_HS_LIST', { is_approved: 1 })

          const yearsList = this.yearsInHSList(false)
          if (yearsList.length === 0) {
            await Swal.fire(
              this.$t('messages.error'),
              'No approved homogeneous sections found!<br><br> \
                    Please create homogeneous sections and approve them before creating treatment works.',
              'error'
            )
            return
          }

          // Create list of years as an object for user selection
          const inputOptions = yearsList.reduce((acc, item) => {
            acc[item.year] = item.year
            return acc
          }, {})

          const { value: theYear } = await Swal.fire({
            title: "Treatment works generations",
            input: "select",
            inputOptions: inputOptions,
            inputPlaceholder: "Select a year",
            showCancelButton: true,
          })

          // Exit if user selects cancel
          if (!theYear) return

          // Check if already generated for this year
          const existingWork = this.savedWorksList.find(el => el.year == theYear)
          if (!existingWork) {
            this.generateWorks(theYear)
            return
          }

          // Treatment list for the selected year exist, ask user whether to delete it
          const { value: confirmDeletion } = await Swal.fire({
            title: 'Treatment works exist!',
            icon: 'warning',
            html: 'List of treatment works for the selected year already exists!<br><br> \
                    Do you want to delete it and generate new list?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            allowEscapeKey: false
          })

          // Exit if user selects cancel
          if (!confirmDeletion) return

          this.showSpinner = true
          try {
            await this.$store.dispatch('DEL_WORK_LIST', existingWork.work_list_id)
            this.generateWorks(theYear)
          } catch (error) {
            console.error('Error while deleting work list:', error)
          }
        } catch (error) {
          console.error('Error in checkAndGenerate:', error)
        }
      },
      generateWorks(year) {
        this.$store.dispatch('GENERATE_WORKS', year).then((res) => {
          this.loadWorksLists()
          this.selectedWorklist = res.work_list_id
          Swal.fire('Generated!', '', 'success')
          this.showSpinner = false
        })
      },
    },

    computed: {
      ...mapState({
        savedWorksList: (state) => state.TitleList.worksLists,
        tableData: (state) => state.TitleList.treatmentsList,
      }),
      ...mapGetters(['treatmentsSummary', 'yearsInHSList']),
      selectedYear() {
        return !this.selectedWorklist ? null : Number(this.savedWorksList.find(el => el.work_list_id == this.selectedWorklist).year)
      },
      footerTable() {
        return [
          this.$t('label.total'),
          `${this.$t('budget.works_count')}: ${numFormat(this.treatmentsSummary.totalRecords, 0)}`,
          `${this.$t('road_network.length_km')}: ${numFormat(this.treatmentsSummary.totalLength / 1000, 3)}`,
          `${this.$t('budget.total_cost')}: ${numFormat(this.treatmentsSummary.totalSum / this.showAmounts, 2)}`,
        ]
      },

    },
    watch: {
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>