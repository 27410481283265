<!--
File: PlanEditForm.vue
Description: form for adding/editing plan, called from Plans.vue
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-100">
            <RegionsDropdown :class="[{ 'md-valid': !errors.has('fk_region') }, { 'md-error': errors.has('fk_region') }]"
              :label="$t('road_network.region')" v-model="fk_region" data-vv-name="fk_region" :initial_value="fk_region"
              :is_required="false" v-validate="modelValidations.fk_region" />

            <md-field :class="[{ 'md-valid': !errors.has('year') }, { 'md-error': errors.has('year') }]">
              <label>{{ $t('budget.year') }}</label>
              <md-input v-model="year" type="number" data-vv-name="year" required v-validate="modelValidations.year"
                min="2021" />
            </md-field>

            <md-field :class="[{ 'md-valid': !errors.has('name') }, { 'md-error': errors.has('name') }]">
              <label>{{ $t('stdCols.name') }}</label>
              <md-input v-model="name" type="text" data-vv-name="name" required v-validate="modelValidations.name"
                :placeholder="$t('label.enter_name')" />
            </md-field>
          </div>
        </div>
        <span v-if="errors.count()>0" class="md-error">{{ $t('messages.errors_count', {count: errors.count()})}}</span>
        <div class="md-layout-item md-size-100 text-right">
          <md-button class="md-success" native-type="submit" @click.native.prevent="validate" :disabled="saveBtnDisabled">
            {{ $t('buttons.save') }}
          </md-button>
          <md-button class="md-accent" @click.stop.prevent="onCLose">
            {{ $t('buttons.close') }}
          </md-button>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import { mapState, mapGetters } from 'vuex'
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import Swal from 'sweetalert2'

  export default {
    name: 'edit-profile-form',
    props: {
      plan: { default: {}, type: Object },
    },
    components: {
      RegionsDropdown
    },
    data() {
      return {
        fk_region: 0,
        year: new Date().getFullYear(),
        name: '',

        modelValidations: {
          fk_region: { required: true, numeric: true, min_value: 1 },
          year: { required: true, numeric: true, min_value: 2021 },
          name: { required: true, min: 3 }
        }
      }
    },
    mounted() {
      if (this.plan) {
        this.fk_region = this.plan.fk_region
        this.year = this.plan.year
        this.name = this.plan.name
      }
    },
    methods: {
      onCLose() {
        this.$emit('close')
      },
      async validate() {
        this.$validator.validateAll().then((isValid) => {
          if (isValid) {
            const planToSave = {
              fk_region: this.fk_region,
              year: Number(this.year),
              name: this.name
            }

            const alert = {
              icon: 'success',
              text: this.$t(`plans.plan_was_saved`, { name: this.name }),
              footer: ''
            }

            const oper = this.plan ? 'UPD' : 'ADD'
            const reqData = this.plan ? { id: this.plan.id, plan: planToSave } : planToSave
            this.$store.dispatch(`${oper}_PLAN`, reqData).then(
              () => {
                Swal.fire(alert).then(() => {
                  this.$nextTick(() => this.$validator.reset())
                  this.$emit('saved')
                })
              },
              (err) => {
                alert.icon = 'error'
                alert.text = this.$t(`plans.plan_was_not_saved`, { name: this.name })
                alert.footer = err
                Swal.fire(alert)
              }
            )
          }
        })
      }
    },
    watch: {
    },
    computed: {
      ...mapState({
        //groups: (state) => state.groups.groups
      }),
      ...mapGetters(['planListByYear']),
      saveBtnDisabled() {
        return this.errors.items.length > 0
      },
    }
  }
</script>
<style>
.md-button+.md-button {
  margin-left: 10px;
}
</style>
