<!--
File: HomosectionSectionInputForm.vue
Description: form for entering parameters for generatin homogeneous sections list.
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-40">
            <BaseDropdown :label="$t('label.from_year')" v-model="selectedYearFrom" :items="years" :displayField="'year'"
              :valueField="'year'" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-40">
            <BaseDropdown :label="$t('label.to_year')" v-model="selectedYearTo" :items="years" :displayField="'year'"
              :valueField="'year'" />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-40">
            <md-field :class="[{ 'md-valid': !errors.has('minHsLength') }, { 'md-error': errors.has('minHsLength') }]">
              <label>{{ $t('label.min_hs_length') }}</label>
              <md-input v-model="minHsLength" type="number" step=100 min=100 max=5000 data-vv-name="minHsLength" required
                v-validate="modelValidations.minHsLength" />
            </md-field>
          </div>
          <div class="md-layout-item md-small-size-100 md-size-40">
            <md-field :class="[{ 'md-valid': !errors.has('ciThreshold') }, { 'md-error': errors.has('ciThreshold') }]">
              <label>{{ $t('stdCols.threshold') }}</label>
              <md-input v-model="ciThreshold" type="number" min=1 max=45 data-vv-name="ciThreshold" required
                v-validate="modelValidations.ciThreshold" />
            </md-field>
          </div>
        </div>
        <div class="md-layout md-size-50">
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-success" native-type="submit" @click.native.prevent="validate"
              :disabled="saveBtnDisabled">
              {{ $t('buttons.generate') }}
            </md-button>
            <md-button class="md-primary" @click.stop.prevent="onClose">
              {{ $t('buttons.close') }}
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import Swal from 'sweetalert2'
  import BaseDropdown from '../Dropdowns/BaseDropdown.vue'

  export default {
    name: 'homosection-parameters-input-form',
    components: {
      BaseDropdown
    },
    props: {
    },
    data() {
      return {
        selectedYearFrom: null,
        selectedYearTo: null,
        years: [],
        minHsLength: 1000,
        ciThreshold: 10,

        modelValidations: {
          minHsLength: { required: true, numeric: true, min_value: 100, max_value: 5000 },
          ciThreshold: { required: true, numeric: true, min_value: 1, max_value: 45 },
        },
      }
    },
    mounted() {
      const thisYear = new Date().getFullYear()
      this.selectedYearTo = thisYear
      this.selectedYearFrom = thisYear - 2
      this.years = [{ year: thisYear - 2 }, { year: thisYear - 1 }, { year: thisYear }]
    },
    methods: {
      onClose() {
        this.$emit('close')
      },
      async validate() {
        this.$validator.validateAll().then((isValid) => {
          const alert = {
            icon: 'success',
            title: this.$t('label.homo_sections'),
            text: this.$t(`label.was_saved`, { name_ru: 'list', name_en: 'list' }),
            footer: ''
          }
          if (isValid) {
            const params = {
              year_from: this.selectedYearFrom,
              year_to: this.selectedYearTo,
              min_hs_length: this.minHsLength,
              condition_index_threshold: this.ciThreshold
            }
            this.$store.dispatch('GENERATE_HS', params).then(
              (res) => {
                this.$emit('input', res.homogeneous_section_list_id)
                console.log('GENERATE_HS', res.homogeneous_section_list_id)
              },
              (err) => {
                alert.icon = 'error'
                alert.text = this.$t(`label.was_not_saved`, { name_ru: '', name_en: '' })
                alert.footer = err
              }
            )
          } else {
            alert.footer = this.$t('messages.errors_count', { count: this.errors.items.length })
            return
          }
          Swal.fire(alert)
        })
        //this.onClose()
      }
    },

    computed: {
      saveBtnDisabled() {
        return this.errors.items.length > 0
      },
    }
  }
</script>
<style>
.md-card {
  margin: 0px 0;
}

.md-button+.md-button {
  margin-left: 10px;
}
</style>
