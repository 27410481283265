<!--
File: Plans.vue
Description: Component of Preparatory.vue, shows list of plans
-->
<template>
  <div>
    <md-card>
      <md-card-header class="md-card-header-icon md-card-header-green">
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-20">
            <RegionsDropdown :label="$t('label.select_region')" v-model="selectedRegion" :is_required='false'
              :initial_value="selectedRegion" @input="loadPlanList" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-15">
            <BaseDropdown :label="$t('budget.select_year')" v-model="selectedYear" :items="yearsInPlanList"
              :displayField="'year'" :valueField="'year'" />
          </div>
          <div class="md-layout-item md-small-size-100 md-size-20">
            <md-field>
              <md-input type="search" class="mb-3 search-input" clearable v-model="searchQuery"
                :placeholder="$t('label.search_records')">
              </md-input>
            </md-field>
          </div>
          <div class="md-layout-item btn-row md-small-size-100">
            <md-button class="md-success" @click="addPlan"> {{ $t('buttons.add') }} </md-button>
          </div>
        </div>
      </md-card-header>
      <md-card-content>
        <md-table class="paginated-table table-striped table-hover" :value="queriedData" :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
          <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')">
          </md-table-empty-state>
          <md-table-row slot="md-table-row" slot-scope="{item}">
            <md-table-cell v-if="!selectedRegion" :md-label="$t('road_network.region')" md-sort-by="region_description">
              {{ item.region_description }}
            </md-table-cell>
            <md-table-cell :md-label="$t('stdCols.name')" md-sort-by="name">
              {{ item.name }}
            </md-table-cell>
            <md-table-cell :md-label="$t('budget.year')" md-sort-by="year" md-numeric>
              {{ item.year }}
            </md-table-cell>
            <md-table-cell :md-label="$t('stdCols.created_at')" md-sort-by="created_at">
              {{ item.created_at | dateFormat }}
            </md-table-cell>
            <md-table-cell :md-label="$t('budget.approved')" md-sort-by="approved_on">
              {{ item.approved_on | dateFormat }}
            </md-table-cell>
            <md-table-cell :md-label="$t('tables.actions')">
              <div class="cell-actions">
                <md-button v-if="!item.approved_on" class="md-just-icon md-success md-simple" @click="editPlan(item)"
                  :title="$t('buttons.edit')">
                  <md-icon>edit</md-icon>
                </md-button>
                <md-button v-if="false" class="md-just-icon md-danger md-simple" @click.stop.prevent="handleDelete(item)">
                  <md-icon>close</md-icon>
                </md-button>
              </div>
            </md-table-cell>
          </md-table-row>
        </md-table>
        <div class="footer-table md-table">
          <table>
            <tfoot>
              <tr>
                <th v-for="item in footerTable" :key="item" class="md-table-head">
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">{{ item }}</div>
                  </div>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </md-card-content>
    </md-card>

    <md-dialog :md-active.sync="showEditForm" :md-click-outside-to-close="false">
      <md-dialog-title>{{ editFormTitle }}
        <md-button class='md-simple md-just-icon md-round modal-default-button' @click='showEditForm = false'>
          <md-icon>clear</md-icon>
        </md-button>
      </md-dialog-title>
      <PlanEditForm :plan="selectedPlan" @close="showEditForm = false" @saved="onPlanSave" />
    </md-dialog>
  </div>
</template>
<script>
  import Fuse from 'fuse.js'
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import BaseDropdown from '../Dropdowns/BaseDropdown.vue'
  import PlanEditForm from './PlanEditForm.vue'
  import Swal from 'sweetalert2'
  import { mapState, mapGetters } from 'vuex'
  import { customSortMixin } from '@/customSortMixin'

  //import Vue from 'vue'
  export default {
    mixins: [customSortMixin],
    name: 'plan-list-component',

    data() {
      return {
        selectedRegion: null,
        selectedYear: null,
        showEditForm: false,
        selectedPlan: null,
        editFormTitle: '',

        searchQuery: '',
        propsToSearch: ['region_description', 'name', 'year'],
        searchedData: [],
        currentSort: 'region_description',
        currentSortOrder: 'asc',
        fuseSearch: null,
      }
    },
    components: {
      RegionsDropdown,
      BaseDropdown,
      PlanEditForm
    },
    mounted() {
      this.loadPlanList()
    },
    methods: {
      loadPlanList() {
        this.$store.dispatch('LOAD_PLAN_LIST', { region_id: this.selectedRegion }).then(() => {
          if (this.tableData && this.tableData.length) {
            this.fuseSearch = new Fuse(this.tableData, {
              keys: this.propsToSearch,
              threshold: 0.3
            });
          }
        })
      },
      editPlan(item) {
        console.log('editPlan')
        this.editFormTitle = this.$t('route.plan_upd')
        this.selectedPlan = item
        this.showEditForm = true
      },
      addPlan() {
        console.log('addPlan')
        this.editFormTitle = this.$t('route.plan_add')
        this.selectedPlan = null
        this.showEditForm = true
      },
      onPlanSave() {
        this.showEditForm = false
        this.loadPlanList()
      }
    },
    computed: {
      ...mapState({
        //planList: (state) => state.Plans.list
      }),
      ...mapGetters(['planListByYear', 'yearsInPlanList']),
      queriedData() {
        const result = !this.searchQuery ? this.tableData : this.searchedData
        return result.slice(this.from, this.to)
      },
      total() {
        return this.tableData.length
      },
      tableData() {
        return this.planListByYear(this.selectedYear)
      },
      footerTable() {
        return [
          this.$t('label.total'),
          ` ${this.total} entries`
        ]
      }
    },
    watch: {
      searchQuery(value) {
        let result = this.tableData
        if (value !== '') {
          result = this.fuseSearch.search(this.searchQuery)
        }
        this.searchedData = result
      }
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.search-input {
  width: 200px; // or use a percentage for more responsiveness
}
</style>
